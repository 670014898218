import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";

const query = graphql`
  query ArticlesQuery {
    craft {
      entries(section: "articles") {
        ... on Craft_articles_default_Entry {
          url
          title
          articleCategory {
            uid
            title
          }
          #articleAuthor {
          #  title
          #}
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          postDate
          categoryLabel: label0
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  return {
    articles: entries?.map(article => {
      return {
        ...article,
        metaImage: resolveImage(article.metaImage),
      };
    }),
  };
};

const useArticlesData = () => {
  const { craft } = useStaticQuery(query);
  return dataResolver(craft);
};

export default useArticlesData;
